<template>
  <module-container
    :title="$t('debt.saved.collection')"
    dense
    hide-new-button
    route-name="invoices.debt"
    show-toolbar
  >
    <template #route>
      <invoices-list
        :hide-headers="['is_cash']"
        :invoice-type="[701, 901]"
        :movement-type="[7, 14, 15]"
        filter-key="debt_saved"
      />
    </template>

    <template #filters>
      <invoice-filters filter-key="debt_saved" />
    </template>
  </module-container>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import InvoicesList from "@/modules/invoices/components/InvoicesList.vue";
import ActiveIcon from "@/components/common/ActiveIcon.vue";
import InvoiceFilters from "@/modules/invoices/components/InvoiceFilters.vue";
import PaginateProviderMixin from "@/mixins/PaginateProviderMixin";

@Component({
  components: { InvoiceFilters, ActiveIcon, InvoicesList },
})
export default class DebtSaved extends Mixins(PaginateProviderMixin) {}
</script>
